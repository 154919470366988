<template>
  <div class="exception-page">
    <div class="img">
      <img :src="config[type].img" />
    </div>
    <div class="content">
      <h1>{{ config[type].title }}</h1>
      <div class="desc">{{ config[type].desc }}</div>
      <div class="action">
        <a-button type="primary" @click="backHome">返回首页</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "./typeConfig";

export default {
  name: "ExceptionPage",
  props: ["type", "homeRoute"],
  data() {
    return {
      config: Config,
    };
  },
  methods: {
    backHome() {
		if (this.$store.state.system.permissionCodes.includes('pc_jobs')) {
			this.$closePage('/');
		} else {
			this.$closePage('/index/welcome');
		}
    },
  },
};
</script>

<style lang="less" scoped>
.exception-page {
  border-radius: 4px;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .img {
    padding-right: 30px;
    zoom: 1;
    img {
      max-width: 300px;
    }
  }
  .content {
    h1 {
      color: #434e59;
      font-size: 60px;
      font-weight: 600;
      line-height: 72px;
      margin-top: 24px;
    }
    .desc {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
}
</style>
