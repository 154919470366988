const config = {
  403: {
    img: require('@/assets/image/403.svg'),
    title: "403",
    desc: "抱歉，你无权访问该页面",
  },
  404: {
    img: require('@/assets/image/404.svg'),
    title: "404",
    desc: "抱歉，你访问的页面不存在或仍在开发中",
  },
  500: {
    img: require('@/assets/image/500.svg'),
    title: "500",
    desc: "抱歉，服务器出错了",
  },
};

export default config;
